import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-independence-missouri.png'
import image0 from "../../images/cities/scale-model-of-the-pioneer-spring-in-independence-missouri.png"
import image1 from "../../images/cities/scale-model-of-leila's-hair-museum-in-independence-missouri.png"
import image2 from "../../images/cities/scale-model-of-national-frontier-trails-museum-in-independence-missouri.png"
import image3 from "../../images/cities/scale-model-of-harry-s-truman-national-historic-site-in-independence-missouri.png"
import image4 from "../../images/cities/scale-model-of-cool-crest-family-fun-center-in-independence-missouri.png"
import image5 from "../../images/cities/scale-model-of-park-visitor-center---harry-s-truman-national-historic-site-in-independence-missouri.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Independence'
            state='Missouri'
            image={image}
            lat='39.0911161'
            lon='-94.4155068'
            attractions={ [{"name": "The Pioneer Spring", "vicinity": "187 N Dodgion St, Independence", "types": ["point_of_interest", "establishment"], "lat": 39.0934114, "lng": -94.41142239999999}, {"name": "Leila's Hair Museum", "vicinity": "1333 S Noland Rd, Independence", "types": ["museum", "point_of_interest", "establishment"], "lat": 39.0760418, "lng": -94.41354639999997}, {"name": "National Frontier Trails Museum", "vicinity": "318 W Pacific Ave, Independence", "types": ["museum", "point_of_interest", "establishment"], "lat": 39.086829, "lng": -94.41941600000001}, {"name": "Harry S Truman National Historic Site", "vicinity": "219 N Delaware St, Independence", "types": ["museum", "point_of_interest", "establishment"], "lat": 39.093754, "lng": -94.4233898}, {"name": "Cool Crest Family Fun Center", "vicinity": "10735 E US-40, Independence", "types": ["amusement_park", "restaurant", "food", "point_of_interest", "establishment"], "lat": 39.0514718, "lng": -94.45257809999998}, {"name": "Park Visitor Center - Harry S Truman National Historic Site", "vicinity": "223 N Main St, Independence", "types": ["park", "point_of_interest", "establishment"], "lat": 39.0935756, "lng": -94.4152148}] }
            attractionImages={ {"The Pioneer Spring":image0,"Leila's Hair Museum":image1,"National Frontier Trails Museum":image2,"Harry S Truman National Historic Site":image3,"Cool Crest Family Fun Center":image4,"Park Visitor Center - Harry S Truman National Historic Site":image5,} }
       />);
  }
}